.vlog-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
    padding: 100px;
  }
  
  .vlog-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    text-align: left; /* Align text to the left */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
  }
  
  .vlog-item img {
    width: 100%;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .vlog-item h2 {
    font-size: 1em;
    margin-bottom: 10px;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis if text overflows */
  }
  
  .vlog-item p {
    color: #555;
    margin-top: auto; /* Push date to the bottom */
    font-size: small;
  }
  
  @media screen and (max-width: 768px) {
    .vlog-list {
      flex-direction: column;
    }
  }