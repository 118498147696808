/*--------head------------*/
.brand-container {
  /* color: #8d0229; */
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}
.brand-name {
  font-family: sans-serif;
  /* color: #8d0229; */
  text-transform: uppercase;
  font-size: 54px;
  text-wrap: balance;
  text-align: center;
  /* letter-spacing: 1px; */
  font-weight: 800;
  /* margin-top: auto; */
}
.brand-container p {
  text-align: center;
  font-size: 14px;
  /* color: #573a00; */
  font-weight: 600;
}
.brand-container h2 {
  /* color: #573a00; */
  font-size: 22px;
  /* text-transform: uppercase; */
}
@media screen and (max-width: 768px) {
  .head {
    background-color: #0000002d;
  }
  .brand-name {
    color: white;
  }
  .brand-container p {
    color: white;
  }
  .brand-container h2 {
    color: white;
  }
}
.container-head-47 {
  display: flex;
  padding-inline: 2rem;
  align-items: center;
}

.head {
  padding: 20px 0;
  color: #fff;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.head .icon {
  margin-left: 10px;
}

@media screen and (max-width: 1024px) {
  .brand-name {
    font-size: 32px;
  }
  .brand-container p {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .social {
    display: none;
  }
  .brand-name {
    font-size: 28px;
    text-align: end;
  }
  .brand-container p {
    text-align: end;
    line-height: 15px;
  }
  .container-head-47 {
    display: flex;
  }
  .head {
    padding-inline: 0;
    /* background-color: #97022c; */
  }
}
@media screen and (max-width: 568px) {
  .brand-name {
    font-size: 20px;
    text-align: end;
  }
  .brand-container p {
    font-size: 10px;
  }
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #97022c;
}
.start {
  background-color: #97022c;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #97022c;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #97022c;
  }
}

.test1231231 {
  background-color: rgba(0, 0, 0, 0.153);
}
