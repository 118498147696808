.welcome-heading {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
}
.welcome-heading p {
  font-size: 14px;
}
.welcome-heading h1 {
  font-size: 32px;
  /* letter-spacing: 0.07em; */
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .welcome-to-vimalagiri {
    visibility: hidden;
  }
}
