.hero {
  /* background-image: url("../../../../src/img/cute-boy-smiling-showing-confidence-giving-thumbs-up-generated-by-artificial-intelligence.jpg");
   */
  background-image: url("../../../../src/img/Cute\ boy@0.75x.jpg");

  /* background-image: url("https://img.freepik.com/free-photo/child-with-autism-living-fantasy-world_23-2151248933.jpg?t=st=1722231397~exp=1722234997~hmac=34e183ae592ed92ae9c7e33aec52cc6c379dbf9320233950bc96dcf95df6097c&w=1380"); */
  /* background-image: url("https://img.freepik.com/free-photo/cute-boy-smiling-showing-confidence-giving-thumbs-up-generated-by-artificial-intelligence_188544-101864.jpg?t=st=1722231544~exp=1722235144~hmac=5f6413eacc92a9044b1edc0e7a838bc18e7eec9d47970e421db4939b443cd4cc&w=1380"); */

  /* background-color: #98bec3; */
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1296px) {
  .hero {
    padding-top: 30%;
  }
}

@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }

  .margin1 {
    height: 40vh;
  }
}
