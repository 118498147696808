@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.home-container {
  width: 100%;
  /* height: 47vh; */
  text-align: center;
  background-color: #97022c;
  padding: 73px;
}

.home-container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.profile-card {
  position: relative;
  width: 260px;
  height: 250px;
  background-color: #fff;
  padding: 30px;
  border-radius: 50%;
  box-shadow: -5px 8px 45px rgba(51, 51, 51, 0.126);
  transition: all 0.4s;
  margin: 0 20px;
}

.profile-card:hover {
  border-radius: 10px;
  height: 260px;
}

.profile-card .img {
  position: relative;
  width: 100%;
  height: 100%;
}

.profile-card:hover img {
  border-radius: 10px;
  transform: translateY(-70px);
}

.img img {
  /* object-fit: cover; */
  /* width: 100%; */
  /* height: 190px; */
  /* border-radius: 50%; */
  transition: all 0.4s;
  z-index: 99;
}

.caption {
  text-align: center;
  transform: translateY(-60px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;
}

.profile-card:hover .caption {
  opacity: 1;
  pointer-events: all;
}

.caption h3 {
  font-size: 21px;
  color: #0c52a1;
  font-weight: 600;
}

.caption p {
  font-size: 15px;
  font-weight: 500;
  margin: 2px 0 12px 0;
}

.caption .social-links a {
  font-size: 21px;
  margin: 0 3px;
  cursor: pointer;
  color: #333;
  transition: all 0.4s;
}

.caption .social-links a:hover {
  color: #0c52a1;
}

@media screen and (max-width: 720px) {
  .admin-team-home-container {
    padding: 2px;
  }
}
